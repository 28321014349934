div.custom-container {
	background-color: palegoldenrod;
}

svg.custom .node circle {
	fill: #F3F3FF;
	stroke: #2593B8;
	stroke-width: 1.0px;
}

svg.custom .node text {
	font-size: 11px;
	background-color: #444;
	fill: #F4F4F4;
	text-shadow: 0 1px 4px black;
}

svg.custom .node {
	
}

svg.custom path.link {
	fill: none;
	stroke: #2593B8;
	stroke-width: 1.0px;
}

svg.custom .problemSet{
    font-size: 11px;
    fill: #F3F3FF;
    stroke:#2593B8;
    cursor: pointer;
    stroke-width: 1.0px;
}