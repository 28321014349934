
  .content {
    font-size: 3em;
    background: white;
    grid-area: content;
    grid-gap: 10px;
    display: grid;
    grid-template-columns: repeat(4, 200px);
    padding: 70px;
    align-items: center;
    float: center;
  }

  .correctCheck {
    grid-area: 1/1/1/1;
    background:green;
    z-index:1;
    opacity: 0;
    border: 1px solid black;
    border-radius: 2px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }

  .correctCheck.on {
    opacity: 1;
    transition: all 2s;
  }

  .problemWrapper {
      display: grid;
  }

  .problem {
    background: PaleGreen;
    font-family: monospace;
    display: grid;
    grid-area: 1/1/1/1;
    grid-template-rows: 20px 70px 70px;
    align-items: center;
    justify-items: center;
    border: 1px solid black;
    border-radius: 2px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    padding: 10px;
    z-index: 2;
  }

  .problem.correct {
      opacity: 0;

      transition: all 2s;
  }

  .problem.wrong{

  }

  .answer.correct {
    
  }

  .answer.wrong {
    border: 4px solid red;
    transition: all 2s;
  }

  .answer {
    width: 80px;
    height: 70px;
    border: 2px solid grey;
    border-radius: 4px;
    text-align:right;
    font-size: 100%;
    font-family: monospace;
    background: palegreen;
    transform: translateX(10px);
    opacity: 100%;
    
  }

  #submit_button {
    grid-area: 3/3/3/5;
    background: palegoldenrod;
    border: 1px solid black;
    border-radius:2px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    text-align: center;
    height: 200px;
  }

  #stars {
    grid-area: 4/1/4/5;
    border: 1px solid black;
    background: paleturquoise;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }

   